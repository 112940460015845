@import url("../../common.less");


.error {
    color: red;
}

.login_sec1 {
    height: 100vh;
    padding-top: 10em;
    padding-left: 10.5em;
    padding-right: 8.5em;
    padding-bottom: 7em;
    position: relative;
    background-image: linear-gradient(to bottom,
            #fff 0%,
            #fff 40%,
            #276FB4 40%,
            #276FB4 100%,
        );

        .user-input-wrp {
            margin-top: 2.5em;
        }

    .logoContainer {
        width: 100%;
        max-width: 33%;

        // position: absolute;
        // display:flex;
        // padding:2em 2em;
        // justify-content: start;
        // float: inline-start;
    }

    // .dataContainer{
    // width:100%;
    .imgContainer {
        position: absolute;
    width: 50em;
    right: 34em;
        // display:flex;
        // justify-content: center;
        // align-items: center;
    }

    .infoContainer {

        position: absolute;
        bottom: 0;
        left: 0;
        top: 50%;
        padding-left: 10em;

        width: 100%;
        max-width: 35%;


        #bottom {
            top: 40%;
            bottom: 0;
            left: 0;
        }

        #bottom1 {
            top: 70%;
            bottom: 0;
            left: 0;
        }

        .login-heading {
            .f32();
            .fontbold();
            .cw();
        }

        .login-text {
            .f20();
            .fontmedium();
            .cw();
        }
    }


    .container {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .card {
            width: 100%;
            border-radius: 20px;
            background: #FFF;
            box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.20);
            padding: 1.5em;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            max-width: 49%;
            min-height: 70vh;
            height: fit-content;

            .info {
                .f20();
                .fontmedium();
                .cl15();

            }

            .info span {
                color: #276FB4;
             
                .fontbold();
            }

            .formContainer {
                .mt-1 {
                    margin-top: 1em;
                }

                .forgot {
                    text-align: right;
                    .f16();
                    color: blue;
                    padding-top: 0.5em;
                    padding-bottom: 1em;
                }

                .submitbtnContainer {
                    padding-top: 2em;
                    text-align: center;

                    

                    .orgBtn {
                        width: 100%;
                         display: flex;
                         justify-content: center;
                         text-align: center;
                         border-radius: 8px;

                         &:before {
                            border-radius: 8px;
                         }

                    //     &:hover{
                    //         border-radius: 8px; 
                    //     }

                     }
                }

            }

            .text1 {
                .f18();
                .fontreg();
                .cl15();
                display: inline-block;
                justify-content: center;
                text-align: center;
                align-items: center;
                padding-top: 1em;
                color: #8D8D8D;

                span {
                    color: #0089ED;
                    .fontmedium();
                    cursor: pointer;
                }
            }


            .title {
                .f45();
                .cl15();
                .fontbold();
                padding-top: 0.5em;
                text-align: center;
            }
        }
    }


}

.otpVerificationModel {
    div {
        .fontmedium();
       
    }
    .resendOtpBtn {
        .fontbold();
        padding-top: 1em;
        margin-left: auto;
        text-decoration: underline;
        cursor: pointer;
    }
}

@media (max-width: 1023px) {

    .btnContainer {
        padding-bottom: 3em;
    }

    .login_sec1 {

        padding-left: 2em;
        padding-right: 2em;
        // padding-bottom: 7.375em;
        position: relative;
        background-image: linear-gradient(to bottom,
                #fff 0%,
                #fff 40%,
                #EF6F21 40%,
                #EF6F21 100%,
            );



        .imgContainer {
            width: 100%;
            height: 100%;
            // display:flex;
            // justify-content: center;
            // align-items: center;
        }

        .infoContainer {
            #bottom {
                position: absolute;
                top: 40%;
                bottom: 0px;
                left: 0;
            }

            #bottom1 {
                position: absolute;
                top: 45%;
                bottom: 0;
                left: 0;
            }

            .login-heading {
                .f32();
                .fontbold();
            }

            .login-text {
                .f20();
                .fontsemibold();
            }
        }

        .container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .card {
                width: 100%;
                border-radius: 20px;
                background: #FFF;
                box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.20);
                padding: 2em;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                max-width: 100%;


                .info {
                    .f20();
                    .fontmedium();
                    .cl15();

                }

                .info span {
                    color: #276FB4;
                    .f20();
                    .fontbold();
                }

                .account {
                    text-align: right;

                }


                .formContainer {
                    .mt-1 {
                        margin-top: 1em;
                    }

                    .forgot {
                        text-align: right;
                        .f16();
                        color: blue;
                        padding-top: 0.5em;
                        padding-bottom: 1em;
                    }

                    .submitbtnContainer {
                        padding-top: 2em;
                        text-align: center;

                        .cta {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            text-align: center;
                            border-radius: 8px;

                        }
                    }

                }

                .text1 {
                    .f18();
                    .fontreg();
                    .cl15();
                    display: inline-block;

                    padding-top: 1em;
                }


                .title {
                    .f45();
                    .cl15();
                    .fontbold();
                    padding-top: 0.5em;
                    text-align: center;
                }
            }
        }
        .desc {
            padding-top: 1.222em;
            .f18();
            .cl15();
            .fontreg();
            text-align: center;
            width: 100%;
            max-width: 65.5%;
        }
    }

   


}
@primary-color: #1DA57A;