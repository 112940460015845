




body {
  margin: 0;
  font-family:'Mulish' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,'AvenirBlack','AvenirMedium','AvenirHeavy','AvenirLight';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow: hidden;
}




/*============================
    General CSS Starts Here   
=============================*/


:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}


[type=submit], button, html [type=button] {
  -webkit-appearance: none;
}

@media(min-width: 2560px) {
  body {
    font-size: 18px !important;
  }

  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1920px) {
  body {
    font-size: 16px !important;
  }

  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1919px) {
  body {
    font-size: 14px !important;
  }

  .my_container {
    max-width: 1298px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1440px) {
  body {
    font-size: 14px !important;
  }

  .my_container {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1366px) {
  body {
    font-size: 13px !important;
  }

  .my_container {
    max-width: 1168px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1280px) {
  body {
    font-size: 12px !important;
  }

  .my_container {
    max-width: 1094px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 1194px) {
  body {
    font-size: 10px !important;
  }

  .my_container {
    max-width: 1019px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 834px) {
  body {
    font-size: 11.5px !important;
  }

  .my_container {
    max-width: 712px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media(max-width: 767px) {
  body {
    font-size: 10px !important;
  }
}

.img-circle {
  border-radius: 100%;
}


/*============================
  General CSS Ends Here    
============================*/


.d-f {
  display: flex;
}

.jc-sb {
  justify-content: space-between;
}

.fluid {
  max-width: 100%;
    height: auto;
}


/* styles.css (or your preferred CSS file) */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.scroll-to-top-button:hover {
  background-color: #0056b3;
}
